import React from "react";
import { NavLink } from "react-router-dom";
import {
  HiOutlineHome,
  HiOutlineInbox,
  HiOutlineChatAlt,
  HiOutlineBell,
  HiOutlineBookOpen,
  HiOutlineUsers,
  HiOutlineUserGroup,
  HiOutlineLocationMarker,
  HiOutlinePrinter,
  HiOutlineReceiptRefund,
  HiOutlineClipboardCheck,
  HiOutlineLogout,
  HiOutlineCog,
  HiOutlineUser,
  HiOutlineFingerPrint,
  HiOutlineUserCircle,
  HiOutlinePresentationChartBar,
  HiOutlineQuestionMarkCircle,
  HiOutlineCalendar,
} from "react-icons/hi";

const Sidebar = () => {
  return (
    <div className="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-gray-50 text-gray-800 fixed z-20">
      <div className="fixed flex flex-col top-0 left-0 w-64 bg-white h-full border-r">
        <div className="flex items-center justify-center h-24 border-b">
          <div className="font-sans font-bold text-sm">PSISIM ONLINE</div>
        </div>
        <div className="overflow-y-auto overflow-x-hidden flex-grow">
          <ul className="flex flex-col py-4 space-y-1">
            <li className="px-5">
              <div className="flex flex-row items-center h-8">
                <div className="text-sm font-bold tracking-wide text-gray-500">
                  Navigation
                </div>
              </div>
            </li>
            <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-indigo-500 hover:border-indigo-500 pr-6"
                    : "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 transition ease-in-out delay-75 duration-300 border-transparent hover:border-indigo-500 pr-6"
                }
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <HiOutlineHome size={21} />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Dashboard
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/inbox"
                className={({ isActive }) =>
                  isActive
                    ? "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-indigo-500 hover:border-indigo-500 pr-6"
                    : "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 transition ease-in-out delay-75 duration-300 border-transparent hover:border-indigo-500 pr-6"
                }
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <HiOutlineInbox size={21} />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Inbox
                </span>
                <span className="px-2 py-0.5 ml-auto text-xs font-sans tracking-wide text-indigo-500 bg-indigo-50 rounded-full">
                  New
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/message"
                className={({ isActive }) =>
                  isActive
                    ? "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-indigo-500 hover:border-indigo-500 pr-6"
                    : "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 transition ease-in-out delay-75 duration-300 border-transparent hover:border-indigo-500 pr-6"
                }
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <HiOutlineChatAlt size={21} />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Messages
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/notifications"
                className={({ isActive }) =>
                  isActive
                    ? "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-indigo-500 hover:border-indigo-500 pr-6"
                    : "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 transition ease-in-out delay-75 duration-300 border-transparent hover:border-indigo-500 pr-6"
                }
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <HiOutlineBell size={21} />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Notifications
                </span>
                <span className="px-2 py-0.5 ml-auto text-xs font-sans tracking-wide text-red-500 bg-red-50 rounded-full">
                  1.2k
                </span>
              </NavLink>
            </li>
            <li className="px-5">
              <div className="flex flex-row items-center h-8">
                <div className="text-sm font-bold tracking-wide text-gray-500">
                  Manajemen Psikologi
                </div>
              </div>
            </li>
            <li>
              <NavLink
                to="/jadwal"
                className={({ isActive }) =>
                  isActive
                    ? "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-indigo-500 hover:border-indigo-500 pr-6"
                    : "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 transition ease-in-out delay-75 duration-300 border-transparent hover:border-indigo-500 pr-6"
                }
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <HiOutlineCalendar size={21} />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Jadwal Konsling
                </span>
              </NavLink>
            </li>

            <li className="px-5">
              <div className="flex flex-row items-center h-8">
                <div className="text-sm font-bold tracking-wide text-gray-500">
                  Manajemen Master
                </div>
              </div>
            </li>
            <li>
              <NavLink
                to="/user"
                className={({ isActive }) =>
                  isActive
                    ? "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-indigo-500 hover:border-indigo-500 pr-6"
                    : "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 transition ease-in-out delay-75 duration-300 border-transparent hover:border-indigo-500 pr-6"
                }
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <HiOutlineUsers size={21} />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  User
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/pemohon"
                className={({ isActive }) =>
                  isActive
                    ? "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-indigo-500 hover:border-indigo-500 pr-6"
                    : "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 transition ease-in-out delay-75 duration-300 border-transparent hover:border-indigo-500 pr-6"
                }
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <HiOutlineUserCircle size={21} />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Pemohon
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/outlet"
                className={({ isActive }) =>
                  isActive
                    ? "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-indigo-500 hover:border-indigo-500 pr-6"
                    : "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 transition ease-in-out delay-75 duration-300 border-transparent hover:border-indigo-500 pr-6"
                }
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <HiOutlineLocationMarker size={21} />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Outlet
                </span>
              </NavLink>
            </li>
            <li className="px-5">
              <div className="flex flex-row items-center h-8">
                <div className="text-sm font-bold tracking-wide text-gray-500">
                  Manajemen Petugas
                </div>
              </div>
            </li>
            <li>
              <NavLink
                to="/petugas"
                className={({ isActive }) =>
                  isActive
                    ? "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-indigo-500 hover:border-indigo-500 pr-6"
                    : "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 transition ease-in-out delay-75 duration-300 border-transparent hover:border-indigo-500 pr-6"
                }
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <HiOutlineUserGroup size={21} />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Petugas
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/lapabsensi"
                className={({ isActive }) =>
                  isActive
                    ? "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-indigo-500 hover:border-indigo-500 pr-6"
                    : "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 transition ease-in-out delay-75 duration-300 border-transparent hover:border-indigo-500 pr-6"
                }
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <HiOutlineFingerPrint size={21} />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Laporan Absensi
                </span>
              </NavLink>
            </li>
            <li className="px-5">
              <div className="flex flex-row items-center h-8">
                <div className="text-sm font-bold tracking-wide text-gray-500">
                  Manajemen Soal
                </div>
              </div>
            </li>
            <li>
              <NavLink
                to="/scoring"
                className={({ isActive }) =>
                  isActive
                    ? "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-indigo-500 hover:border-indigo-500 pr-6"
                    : "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 transition ease-in-out delay-75 duration-300 border-transparent hover:border-indigo-500 pr-6"
                }
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <HiOutlinePresentationChartBar size={21} />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Master Scoring
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/mastersoal"
                className={({ isActive }) =>
                  isActive
                    ? "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-indigo-500 hover:border-indigo-500 pr-6"
                    : "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 transition ease-in-out delay-75 duration-300 border-transparent hover:border-indigo-500 pr-6"
                }
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <HiOutlineBookOpen size={21} />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Master Soal
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/instruction"
                className={({ isActive }) =>
                  isActive
                    ? "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-indigo-500 hover:border-indigo-500 pr-6"
                    : "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 transition ease-in-out delay-75 duration-300 border-transparent hover:border-indigo-500 pr-6"
                }
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <HiOutlineQuestionMarkCircle size={21} />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Instruction
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/soalactive"
                className={({ isActive }) =>
                  isActive
                    ? "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-indigo-500 hover:border-indigo-500 pr-6"
                    : "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 transition ease-in-out delay-75 duration-300 border-transparent hover:border-indigo-500 pr-6"
                }
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <HiOutlineClipboardCheck size={21} />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Soal Aktif
                </span>
              </NavLink>
            </li>
            <li className="px-5">
              <div className="flex flex-row items-center h-8">
                <div className="text-sm font-bold tracking-wide text-gray-500">
                  Manajemen Transaksi
                </div>
              </div>
            </li>
            <li>
              <NavLink
                to="/mutasi"
                className={({ isActive }) =>
                  isActive
                    ? "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-indigo-500 hover:border-indigo-500 pr-6"
                    : "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 transition ease-in-out delay-75 duration-300 border-transparent hover:border-indigo-500 pr-6"
                }
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <HiOutlineReceiptRefund size={21} />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Mutasi Transaksi
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/laptransaksi"
                className={({ isActive }) =>
                  isActive
                    ? "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-indigo-500 hover:border-indigo-500 pr-6"
                    : "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 transition ease-in-out delay-75 duration-300 border-transparent hover:border-indigo-500 pr-6"
                }
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <HiOutlinePrinter size={21} />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Laporan Transaksi
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/lapmutasi"
                className={({ isActive }) =>
                  isActive
                    ? "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-indigo-500 hover:border-indigo-500 pr-6"
                    : "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 transition ease-in-out delay-75 duration-300 border-transparent hover:border-indigo-500 pr-6"
                }
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <HiOutlinePrinter size={21} />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Laporan Mutasi
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/lapeppsi"
                className={({ isActive }) =>
                  isActive
                    ? "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-indigo-500 hover:border-indigo-500 pr-6"
                    : "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 transition ease-in-out delay-75 duration-300 border-transparent hover:border-indigo-500 pr-6"
                }
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <HiOutlinePrinter size={21} />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Laporan EPPSI
                </span>
              </NavLink>
            </li>
            <li className="px-5">
              <div className="flex flex-row items-center h-8">
                <div className="text-sm font-bold tracking-wide text-gray-500">
                  Settings
                </div>
              </div>
            </li>
            <li>
              <NavLink
                to="/account"
                className={({ isActive }) =>
                  isActive
                    ? "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-indigo-500 hover:border-indigo-500 pr-6"
                    : "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 transition ease-in-out delay-75 duration-300 border-transparent hover:border-indigo-500 pr-6"
                }
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <HiOutlineUser size={21} />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Profile
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/settings"
                className={({ isActive }) =>
                  isActive
                    ? "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-indigo-500 hover:border-indigo-500 pr-6"
                    : "relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 transition ease-in-out delay-75 duration-300 border-transparent hover:border-indigo-500 pr-6"
                }
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <HiOutlineCog size={21} />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Settings
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                href="/logout"
                className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 transition ease-in-out delay-75 duration-300 border-transparent hover:border-indigo-500 pr-6"
              >
                <span className="inline-flex justify-center items-center ml-4">
                  <HiOutlineLogout size={21} />
                </span>
                <span className="ml-2 text-sm tracking-wide truncate">
                  Logout
                </span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
